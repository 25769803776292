import userEvent from "@testing-library/user-event"
import "../w3.css"
import "./Content.css"

export default function Content({activeUserData, activeUserID, setLocalCount}) {



  return (
    <div className="w3-container">
        <div className="container">
            <div className="UpperLeft">
                <h2>Name:</h2>
                <h1>{activeUserData.name}</h1>
            </div>
            <div className="UpperRight">
                <h2>Count:</h2>
                <h1>{activeUserData.count}</h1>
            </div>
            <div className="LowerLeft">
                <button onClick={setLocalCount}>+</button>
            </div>
            <div className="LowerRight">
                <button onClick={setLocalCount}>-</button>
            </div>
        </div>
    </div>
  )
}
