
import { useState } from "react"
import Content from "../../components/Content"
import Sidebar from "../../components/Sidebar"
import { useAuthContext } from "../../hooks/useAuthContext"
import { useCollection } from "../../hooks/useCollection"
import { useFirestore } from "../../hooks/useFirestore"


export default function Home() {

    const [activeUser, setActiveUser] = useState("")
    const [activeUserData, setActiveUserData] = useState(null)
    const { documents, error } = useCollection("users")
    const { changeDocument } = useFirestore("users")
    const { user } = useAuthContext()

    const handleUserClick = (user) => {
      setActiveUser(user.id)
      setActiveUserData(user)
    }

    const setLocalCount = (e) => {
      if (e.target.innerText === "+"){
        let newCount = activeUserData.count += 1
        setActiveUserData({...activeUserData, count: newCount})
        changeDocument(activeUser, activeUserData)
      } else if (e.target.innerText === "-"){
        let newCount = activeUserData.count -= 1
        setActiveUserData({...activeUserData, count: newCount})
        changeDocument(activeUser, activeUserData)
      }
    }

  return (
    <>
    {user &&
    <div>  
      <div>
        {error && <p>{error}</p>}
        {documents && <Sidebar users={documents} activeUser={activeUser} handleUserClick={handleUserClick} />}
      </div>
      <div>
        {activeUser && <Content activeUserData={activeUserData} activeUserID={activeUser} setLocalCount={setLocalCount} />}
      </div>
    </div>}
    </>
  )
}
