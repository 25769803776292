import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { useAuthContext } from "./hooks/useAuthContext"


// styles
import './App.css';
import Home from './pages/home/Home';
import Login from './pages/login/Login';

function App() {

  const { authIsReady, user } = useAuthContext()

  return (
    <div className="App">
      <Home />
    {authIsReady && (
      <BrowserRouter>
      <Switch>
        <Route exact path="/">
          {!user && <Redirect to="/login" />}
          {user && <Home />}
        </Route>
        <Route path="/login">
          {user && <Redirect to="/" />}
          {!user && <Login />}
        </Route>
      </Switch>
    </BrowserRouter>
    )}
    </div>
  );
}

export default App;
