import { useAuthContext } from "../hooks/useAuthContext"
import { useLogout } from "../hooks/useLogout"
import "../w3.css"
import "./Sidebar.css"

export default function Sidebar({ users, activeUser, handleUserClick }) {
    const { authIsReady, user } = useAuthContext()
    const { logout } = useLogout()
  return (
    <>
    <div className="w3-sidebar w3-bar-block list">
        <h3 className="w3-bar-item">💩</h3>
            {user && <button className="userBtn" onClick={logout}>Logout</button>}
            {/* {!user && <button className="userBtn" onClick={login}>Login</button>} */}
            {users.map((user) => (
               <a key={user.id} className={user.id === activeUser ? "isActive w3-bar-item w3-button" : "w3-bar-item w3-button"} onClick={() => handleUserClick(user)}>
                   <p className="name">{user.name}</p>
               </a>
           ))}
    </div>
    </>
  )
}
