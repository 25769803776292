import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'

const firebaseConfig = {

    apiKey: "AIzaSyDYVIpIAkwcE8o8COtliRgT8l8Y494LAr0",
    authDomain: "maxi-kaka-de.firebaseapp.com",
    databaseURL: "https://maxi-kaka-de-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "maxi-kaka-de",
    storageBucket: "maxi-kaka-de.appspot.com",
    messagingSenderId: "917028439988",
    appId: "1:917028439988:web:bf0e2133369c189c0514e1"
  };

// init firebase
firebase.initializeApp(firebaseConfig)

// init service
const projectFirestore = firebase.firestore()
const projectAuth = firebase.auth()

export { projectFirestore, projectAuth }